<template>
    <section class="pqr-solicitudes">
        <cargando v-if="cargando" />
        <div class="row p-4">
            <div class="col-12 col-lg-auto">
                <p class="f-20 f-500">Propietarios</p>
            </div>
            <div class="col ml-auto my-auto">
                <div class="row justify-content-lg-end d-middle-center">
                    <div class="row justify-content-lg-end">
                        <export-excel
                        :data="exportarPropietarios"
                        :fields="excel.campos"
                        :before-generate="() => excel.loading = true"
                        :before-finish="() => excel.loading = false"
                        worksheet="propietarios"
                        name="plantilla.xls"
                        >
                            <button v-loading="excel.loading" class="d-middle border bg-f5 br-8 h-32px f-300 f-14 mx-2 pr-3">
                                <i class="icon-download f-20" />
                                <span>Exportar</span>
                            </button>
                        </export-excel>
                        <div class="col-auto mx-2">
                            <div class="row bg-f5 border br-8 indicadores">
                                <div class="col-auto my-auto">
                                    <p class="f-17 f-500">
                                        {{ total }}
                                        <span class="f-100 pl-2 f-13 text-muted">Total</span>
                                    </p>
                                </div>
                                <div class="col pr-0">
                                    <div class="indicador1 d-middle-center">
                                        <i class="f-20 icon-account-outline op-05" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto px-0" @click="crearPropietario">
                            <button class="btn btn-general h-32px f-300 f-14 mx-2">
                                Nuevo
                                propietario
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-auto">
                <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" />
            </div>
        </div>
        <!-- tabla -->
        <div class="row row overflow-auto custom-scroll" style="height:calc(100vh - 206px)">
            <div class="col-12">
                <el-table :data="propietarios" height="calc(100vh - 215px)" style="width: 100%" @row-click="verPropietario">
                    <el-table-column sortable prop="grupo_vivienda" min-width="70" label="Grupo de viviendas">
                        <template slot-scope="scope">
                            {{ scope.row.grupo_vivienda }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="nombre_vivienda" label="Vivienda">
                        <template slot-scope="scope">
                            {{ scope.row.nombre_vivienda }}
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="nombre_propietario" label="Propietario" />
                    <!-- <el-table-column sortable prop="nombre_propietario" label="Propietario">
						<template slot-scope="scope">
							{{ scope.row.nombre_propietario }}
						</template>
					</el-table-column> -->
                    <el-table-column sortable prop="genero" label="Genero" align="center">
                        <template slot-scope="scope">
                            <span>{{ scope.row.genero }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column sortable prop="telefono" label="Telefono">
                        <template slot-scope="scope">
                            <div class="">
                                <p>{{ scope.row.telefono }}</p>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="" width="50">
                        <template slot-scope="scope">
                            <!-- Solo sale en cierto tipo -->
                            <el-tooltip placement="bottom-end" effect="light" :visible-arrow="false" popper-class="editar-residente br-4">
                                <div slot="content" class="tooltip-editar-residente">
                                    <div class="d-flex mb-2 editar-opcion py-1 px-1 cr-pointer br-4" @click="irEditarPropietario(scope.row.id)">
                                        <i class="icon-pencil-outline mr-2 f-17" />
                                        <p class="f-15">Editar datos</p>
                                    </div>
                                    <div class="d-flex my-auto editar-opcion py-1 px-1 cr-pointer br-4" @click="modalEliminarResidente(scope.row.id)">
                                        <i class="icon-delete-outline my-auto mr-2 f-17" />
                                        <div>
                                            <p class="f-15">Eliminar a {{ scope.row.nombre_propietario }}</p>
                                            <p class="f-13 text-86">Todo registro será borrado del sistema</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="mas-opciones d-middle-center br-4 f-15 cr-pointer ml-auto">
                                    <i class="icon-vertical-points" />
                                </div>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <div slot="empty" class="row">
                        <div class="col-12 text-center">
                            <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                            <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">
                                No hemos encontrado coincidencias
                                con tu búsqueda
                            </p>
                        </div>
                    </div>
                    <infinite-loading slot="append" force-use-infinite-wrapper=".el-table__body-wrapper" @infinite="listar" />
                </el-table>
            </div>
        </div>
        <modal-eliminar ref="abrirEliminarPropietario" titulo="Eliminar propietario" mensaje="¿Desea eliminar este propietario?" adicional="Rechazar" tamano="modal-l" @eliminar="eliminarPropietario">
            No podrá acceder a Mi Conjunto y todo registro será borrado del sistema
        </modal-eliminar>
    </section>
</template>
<script>
import Vue from 'vue'
import excel from 'vue-excel-export'
import InfiniteLoading from 'vue-infinite-loading';
import Propietarios from '~/services/propietarios'

Vue.use(excel)
export default {
    components: {
        InfiniteLoading
    },
    data(){
        return {
            generos: {
                1: 'Otro',
                2: 'Masculino',
                3: 'Femenino',
            },
            propietarios: [],
            total: null,
            page: 1,
            search: '',
            cargando: false,
            idPropietario: null,
            exportarPropietarios: [],
            excel: {
                loading: false,
                campos: {
                    'Torre': 'grupo_vivienda',
                    'Vivienda': 'nombre_vivienda',
                    'Propietario': 'nombre_propietario',
                    'Fecha nacimiento': 'fecha_nacimiento',
                    'Genero': 'genero',
                    'Correo': 'correo',
                    'Telefono': 'telefono',
                },
            },
        }
    },
    watch: {
        search(){
            this.buscador()
        }
    },
    created(){
        this.exportPropietarios()
    },
    methods: {
        verPropietario(item){
            this.$router.push({ name: 'propietarios.ver', params: { id_residente: item.id } })
        },
        crearPropietario(){
            this.$router.push({ name: 'propietarios.crear' })
        },
        irEditarPropietario(item){
            this.$router.push({ name: 'propietarios.editar', params: { idPropietario: item } })
        },
        async listar($state){
            try {
                this.cargando = true
                const state = $state
                const params = {
                    search: this.search,
                    page: this.page
                }
                const { data } = await Propietarios.getListaPropietarios({ params })
                this.total = data.mensaje;
                if (data.data.data.length){
                    this.page += 1;
                    this.propietarios.push(
                        ...data.data.data.map(el => {
                            el.genero = this.generos[el.genero];
                            return el;
                        })
                    )
                    if (typeof state !== 'undefined'){
                        state.loaded();
                    }
                } else {
                    if (typeof state !== 'undefined'){
                        state.complete();
                    }
                }
            } catch (e){
                this.errorCatch(e)
            } finally {
                this.cargando = false
            }
        },
        async buscador(){
            const busqueda = async() => {
                this.propietarios = []
                this.page = 1
                this.listar()
            }
            await this.delay(busqueda)
        },
        modalEliminarResidente(item){
            this.idPropietario = item;
            this.$refs.abrirEliminarPropietario.toggle()
        },
        async eliminarPropietario(){
            try {
                const { data } = await Propietarios.deletePropietario(this.idPropietario)
                this.notificacion('Éxito', data.mensaje, 'success')
                let index = this.propietarios.findIndex(i => i.id === data.id);
                this.propietarios.splice(index, 1)
                this.$refs.abrirEliminarPropietario.toggle()
                this.idPropietario = null;
                this.total -= 1
            } catch (error){
                return this.errorCatch(error);
            }
        },
        async exportPropietarios(){
            try {
                const { data } = await Propietarios.exportarExcel();
                this.exportarPropietarios = data.data;
                this.excel.loading = false;
            } catch (e){
                this.errorCatch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>

</style>